import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "panther-athletics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#panther-athletics",
        "aria-label": "panther athletics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther Athletics`}</h3>
    <p>{`Panther Athletics had an unusual year with the pandemic cancelling all CEESA events that were planned with face to face competitions. Some schools set up invitational virtual sporting events. PSI Panthers competed in a 3 point Virtual Basketball tournament and our 3 students earned a bronze medal, 4th and 7th place out of 80 competitors.`}</p>
    <p>{`A new programme called Cohort Sports Programme was developed and used for the year. This programme allowed students to train mostly on their individual skills and fitness related to the sports they chose based on pandemic safety regulations. During the year the numbers of athletes increased as they became more accustomed to the rules and regulations. Our new programme also allowed for us to have different sports at different times of the year and a few new sports added - winter distance running and badminton.`}</p>
    <p>{`Coach education was further developed and PSI Panther coaches are now taking steps towards achieving NFHS Level 1 Honour Roll Status with a number of coaches completing courses online.`}</p>
    <p>{`The Distance Learning Athletics Programme was set up for when the students were required to work remotely and various activities were offered by coaches to both students and parents. Activities included online courses, social online activities, fitness workouts and even student v coaches challenges.`}</p>
    <p>{`The Student Activities Leadership Team (S.A.L.T) worked on a new Panther vision statement, mission statement, core values and what it means to be a Panther ready for release in 2021 / 2022.`}</p>
    <p>{`We also introduced a new logo for our primary programmes with the Panther Cub logo competition.`}</p>
    <p>{`Our coaches and athletes are looking forward to moving on with having some of our usual athletics programmes again but also hoping to enhance them with various ideas gained from this unique global experience.`}</p>
    <h3 {...{
      "id": "panther-academics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#panther-academics",
        "aria-label": "panther academics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther Academics`}</h3>
    <p>{`The Panthers Academic programme faced a unique challenge due to the global pandemic and our blended model. The situation hasn’t stopped PSI from turning these circumstances in our favour. This year, we had a record number of participation in the Middle School (MS) Mathcounts programme. Also, one of our MS students qualified for the invitation round and finished in the top 10 amongst the CEESA schools. In partnership with Warsaw, we have co-hosted the CEESA High School (HS) Math competition and had our HS math team compete after a long hiatus. The Yale Model Government Europe (YMGE) Conference has been moved to the end of the school year, where we will represent PSI with a delegation of 7 students.`}</p>
    <p>{`As we go ahead with next year we look forward to more growth, leadership, and success from our students.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      